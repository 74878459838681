import React, { useState, useEffect, useRef } from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import "./bootstrap.min.css"
import Header from "./header"
import "./layout.css"
import styled from "styled-components"
import { useWindowSize } from "../../templates/useWindowSize"

const ContentStyle = styled.div`
  position: relative;
  top: 90px;
  transform: ${props =>
    props.menuActiveUp
      ? `translateY(${props.heightMenu}px)`
      : "translateY(0px)"};
  transition-property: padding, transform;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  background-color: #090909;

  @media all and (min-width: 576px) {
    padding-left: ${props => (props.menuActive ? "280px" : "0px")};
  }
`

const Layout = props => {
  const [menuActive, setMenuActive] = useState(true)
  const [menuActiveUp, setMenuActiveUp] = useState(false)
  const [heightMenu, setHeightMenu] = useState(65)
  const size = useWindowSize()
  const menuUpRef = useRef(null)
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  useEffect(() => {
    if (menuUpRef.current) {
      setHeightMenu(menuUpRef.current.clientHeight)
    }
  }, [size.width])

  useEffect(() => {
    if (props.pathname === "/") {
      setMenuActive(false)
    }
  }, [props.pathname])

  const handleMenu = () => {
    if (props.pathname !== "/") {
      setMenuActive(prevState => {
        return !prevState
      })
    }
  }
  const handleMenuUp = () => {
    setHeightMenu(menuUpRef.current.clientHeight)
    if (props.pathname !== "/") {
      setMenuActiveUp(prevState => {
        return !prevState
      })
    }
  }
  return (
    <>
      <Header
        siteTitle={data.site.siteMetadata.title}
        title={props.title ? props.title : "Wybierz język"}
        handleMenu={handleMenu}
        menuActive={menuActive}
        menuLeft={props.menuLeft ? props.menuLeft : null}
        menuActiveUp={menuActiveUp}
        handleMenuUp={handleMenuUp}
        pathname={props.pathname}
        menuUpRef={menuUpRef}
        heightMenu={heightMenu}
      />
      <ContentStyle
        menuActiveUp={menuActiveUp}
        menuActive={menuActive}
        heightMenu={heightMenu}
      >
        {props.children}
      </ContentStyle>
    </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
