import { navigate } from "gatsby"
import React from "react"
import styled from "styled-components"
import { dataPages } from "../../data/dataPages"
import { Link } from "react-scroll"
import { BsLayoutSidebarInset } from "react-icons/bs"
import { FaRobot } from "react-icons/fa"

const MenuPosition = styled.div`
  position: absolute;
  top: 0px;
  height: ${props =>
    !props.menuActiveUp ? "calc(100vh - 48px)" : "calc(100vh - 100px)"};
  left: ${props => (props.menuActive ? "0" : "0px")};
  transform: ${props =>
    props.menuActive ? "translateX(0px)" : "translateX(-280px)"};
  width: 280px;
  text-align: center;
  padding: 10px;
  padding-bottom: ${props => (props.menuActiveUp ? "25px" : "11px")};
  background-color: #0084d0;
  transition-property: left, transform, height, padding-bottom;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  z-index: 1000;
  overflow-y: auto;

  ::-webkit-scrollbar-thumb {
    background-color: #23aaf2;
    border-radius: 10px;
    transition-property: background-color;
    transition-duration: 0.3s;
    transition-timing-function: ease;
  }
`

const TitleStyle = styled.div`
  position: relative;
  text-align: center;
  color: white;
  font-size: 2rem;
  background-color: #23aaf2;
  padding: 5px;
  font-weight: 700;
  transition-property: padding;
  transition-duration: 0.5s;
  transition-timing-function: ease;

  @media all and (min-width: 576px) {
    padding-left: ${props => (props.menuActive ? "280px" : "")};
  }
`

const NavPosition = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  box-shadow: 0px 0px 10px 5px rgba(0, 0, 0, 0.5);
  transform: ${props =>
    !props.menuActiveUp
      ? `translateY(-${props.heightMenu}px)`
      : "translateY(0px)"};
  z-index: 1000;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-in;
`

const NavStyle = styled.div`
  background-color: #090909;
  color: white;
`

const FlexContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  padding: 10px;
`

const ButtonNavUpStyle = styled.button`
  background-color: ${props => (props.menuValue ? "#23aaf2" : "#23aaf2")};
  color: ${props => (props.menuValue ? "white" : "white")};
  padding: ${props => (props.menuValue ? "5px 10px" : "2px 10px")};
  border: none;
  margin: ${props => (props.menuValue ? "0" : "5px")};
  margin-bottom: 5px;
  border-radius: ${props => (props.menuValue ? "10px" : "5px")};
  outline: none;
  font-weight: 500;
  transition-property: background-color, transform;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  word-break: break-word;
  &:focus,
  &:active {
    outline: none;
  }

  &:hover {
    transform: ${props => (props.menuValue ? "scale(1.05)" : "scale(1.2)")};
  }
`

const ButtonNavStyle = styled(Link)`
  background-color: #23aaf2;
  color: white !important;
  word-break: break-word;
  padding: 5px 10px;
  border: none;
  margin: 0;
  font-size: 0.9rem;
  line-height: 1rem;
  margin-bottom: 5px;
  font-weight: 500;
  border-radius: 10px;
  outline: none;
  min-width: 100%;
  display: inline-block;
  cursor: pointer;
  transition-property: background-color, transform;
  transition-duration: 0.5s;
  transition-timing-function: ease;
  &:focus,
  &:active {
    outline: none;
  }

  &:hover {
    transform: scale(1.05);
  }
`

const MenuActiveLanguages = styled.div`
  position: absolute;
  top: 45%;
  left: 10px;
  transform: translateY(-50%);
  color: white;
  font-size: 2rem;
  cursor: pointer;
  transition-property: background-color, transform;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  &:focus,
  &:active {
    outline: none;
  }

  &:hover {
    transform: translateY(-50%);
  }
`

const MenuActive = styled.div`
  position: absolute;
  top: 48%;
  left: 60px;
  transform: translateY(-50%);
  font-size: 1.5rem;
  color: white;
  cursor: pointer;
  transition-property: background-color, transform;
  transition-duration: 0.3s;
  transition-timing-function: ease;
  &:focus,
  &:active {
    outline: none;
  }

  &:hover {
    transform: translateY(-50%);
  }
`

const PositionRelative = styled.div`
  position: relative;
`

const Header = ({
  title,
  handleMenu,
  menuActive,
  menuLeft,
  handleMenuUp,
  menuActiveUp,
  pathname,
  menuUpRef,
  heightMenu,
}) => {
  const data = dataPages

  const handleClickLanguage = slug => {
    navigate(`/${slug}`)
  }

  const pages = data.map((item, index) => {
    return (
      <React.Fragment key={index}>
        {pathname !== `/${item.page}` ? (
          <ButtonNavUpStyle onClick={() => handleClickLanguage(item.page)}>
            {item.title}
          </ButtonNavUpStyle>
        ) : null}
      </React.Fragment>
    )
  })

  const renderMenuLeft = menuLeft ? (
    <MenuPosition menuActive={menuActive} menuActiveUp={menuActiveUp}>
      {menuLeft.map((item, index) => {
        return (
          <ButtonNavStyle
            activeClass="activeMenu"
            to={item.toLowerCase()}
            spy={true}
            smooth={true}
            offset={menuActiveUp ? -155 : -90}
            duration={500}
            key={index}
          >
            {item}
          </ButtonNavStyle>
        )
      })}
    </MenuPosition>
  ) : null

  return (
    <>
      <NavPosition
        menuActive={menuActive}
        menuActiveUp={menuActiveUp || pathname === `/` ? true : false}
        heightMenu={heightMenu}
      >
        <NavStyle ref={menuUpRef}>
          <div className="container">
            <FlexContainer>{pages}</FlexContainer>
          </div>
        </NavStyle>
        <TitleStyle
          menuActive={menuActive && pathname !== `/`}
          className="text-uppercase"
        >
          {title}
          {pathname !== `/` ? (
            <MenuActive onClick={handleMenu}>
              <BsLayoutSidebarInset />
            </MenuActive>
          ) : null}
          {pathname !== `/` ? (
            <MenuActiveLanguages onClick={handleMenuUp}>
              <FaRobot />
            </MenuActiveLanguages>
          ) : null}
        </TitleStyle>
        <PositionRelative>{renderMenuLeft}</PositionRelative>
      </NavPosition>
    </>
  )
}

export default Header
